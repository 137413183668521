export const GC_USER_ID = 'graphcool-user-id'
export const GC_AUTH_TOKEN = 'graphcool-auth-token'
export const GC_USER_ROLE = 'graphcool-user-role'
export const GC_LAB_ID = 'graphcool-lab-id'
export const GC_IS_APP = 'graphcool-is-app'
export const GC_LAST_LOGIN = 'graphcool-last-login'
export const GC_PAYMENT = 'graphcool-payment-status'
export const LIMIT_METAL_SETTING = 10
export const PAGINATION_LIMIT = 10000
export const LAB_IMAGE_URL =  process.env.VUE_APP_S3_URL + "/lab/"
export const CLINIC_IMAGE_URL =  process.env.VUE_APP_S3_URL + "/clinic/"
export const SIGN_IMAGE_URL =  process.env.VUE_APP_S3_URL + "/sign/"
export const VUE_APP_OTP_MERCHANT_ID = process.env.VUE_APP_OTP_MERCHANT_ID
export const VUE_APP_OTP_SERVICE_ID = process.env.VUE_APP_OTP_SERVICE_ID
export const LINK_CREDIT_OTP_JS_FILE = process.env.VUE_APP_CREDIT_OTP_JS_FILE
export const VUE_APP_URL_LABO = process.env.VUE_APP_URL_LABO
export const VUE_APP_URL_CLINIC = process.env.VUE_APP_URL_CLINIC